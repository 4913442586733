import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import logosmdark from "../../assets/images/logo-sm-dark.png";
import logodark from "../../assets/images/logo-dark.jpg";
import logosmlight from "../../assets/images/logo-sm-light.png";
import logolight from "../../assets/images/logo-dark.jpg";
import user from "../../assets/images/user.png";

import Language from "./Language";
import { logOut } from "../../store/reducer/SignInReducer";
function Header() {
  const username = localStorage.getItem("username");
  const dispatch = useDispatch();
  const [menu, setMenu] = useState(false);
  const toggle = () => {
    setMenu(!menu);
  };
  let template_settings = JSON.parse(localStorage.getItem("template"));

  const toggleSideBar = () => {
    template_settings.sidebar = !template_settings.sidebar;
    localStorage.setItem("template", JSON.stringify(template_settings));
    if(template_settings.sidebar){
        document.body.classList.add("sidebar-enable", "vertical-collpsed")
    }else{
        document.body.classList.remove("sidebar-enable", "vertical-collpsed")
    }
  };
  const logout = () => {
      dispatch(logOut());
  }

  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  };

  return (
    <>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box" style={{background:"white"}}>
              <Link to="#" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logosmdark} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logodark} alt="" height="20" />
                </span>
              </Link>

              <Link to="#" className="logo logo-light" style={{textAlign:"center"}}>
                <span className="logo-sm">
                  <img src={logosmlight} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logolight} alt="" height="80" />
                </span>
              </Link>
            </div>

            <Button
              size="sm"
              color="none"
              type="button"
              className="px-3 font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
              onClick={toggleSideBar}
            >
              <i className="ri-menu-2-line align-middle"></i>
            </Button>
          </div>

          <div className="d-flex">
            <Language/>
            <div className="dropdown d-none d-lg-inline-block ms-1">
              <Button
                color="none"
                type="button"
                className="header-item noti-icon waves-effect"
                onClick={toggleFullscreen}
              >
                <i className="ri-fullscreen-line"></i>
              </Button>
            </div>

            <Dropdown
              isOpen={menu}
              toggle={toggle}
              className="d-inline-block user-dropdown"
            >
              <DropdownToggle
                tag="button"
                className="btn header-item waves-effect"
                id="page-header-user-dropdown"
              >
                <img
                  className="rounded-circle header-profile-user me-1"
                  src={user}
                  alt="Header Avatar"
                />
                <span className="d-none d-xl-inline-block ms-1 text-transform">
                  {username}
                </span>
                <i className="mdi mdi-chevron-down d-none ms-1 d-xl-inline-block"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-end">
                {/* <DropdownItem href="#">
                  <i className="ri-user-line align-middle me-1"></i> {"Profile"}
                </DropdownItem>
                <DropdownItem href="#">
                  <i className="ri-wallet-2-line align-middle me-1"></i>{" "}
                  {"My Wallet"}
                </DropdownItem>
                <DropdownItem className="d-block" href="#">
                  <span className="badge badge-success float-end mt-1">11</span>
                  <i className="ri-settings-2-line align-middle me-1"></i>{" "}
                  {"Settings"}
                </DropdownItem>
                <DropdownItem href="#">
                  <i className="ri-lock-unlock-line align-middle me-1"></i>{" "}
                  {"Lock screen"}
                </DropdownItem>
                <DropdownItem divider /> */}
                <DropdownItem className="text-danger" onClick={logout}>
                  <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
                  {"Logout"}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
